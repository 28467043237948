@import  './fonts.css';

body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    width: 100%;
    font-family: "Noto Sans Devanagari";
    font-weight: 500;
    background: rgb(255, 255, 255);
  }
  a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
  }
  a:not(.btn):hover {
    text-decoration: underline;
  }
  input,
  select,
  textarea,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-size: inherit;
    font-weight: inherit;
  }
  main {
    overflow: hidden;
  }
  u > span {
    text-decoration: inherit;
  }
  ol,
  ul {
    padding-left: 2.5rem;
    margin: 0.625rem 0;
  }
  p {
    word-wrap: break-word;
  }
  h1 > span,
  h2 > span,
  h3 > span,
  h4 > span,
  h5 > span,
  h6 > span {
    display: block;
    word-wrap: break-word;
  }
  iframe {
    border: 0;
  }
  * {
    box-sizing: border-box;
  }
  :root.js-text-scaling {
    --mobile-font-size: 4vw;
    --default-font-size: 16px;
  }
  .item-absolute {
    position: absolute;
  }
  .item-relative {
    position: relative;
  }
  .item-block {
    display: block;
    height: 100%;
    width: 100%;
  }
  .item-cover {
    z-index: 1000030;
  }
  .item-breakword {
    word-wrap: break-word;
  }
  .item-content-box {
    box-sizing: content-box;
  }
  .hidden {
    display: none;
  }
  .clearfix {
    clear: both;
  }
  sup {
    margin-left: 0.1rem;
    line-height: 0;
  }
  @keyframes slide-down {
    from {
      opacity: 0;
      transform: translateY(-50px);
    }
  }
  @keyframes fade-in {
    from {
      opacity: 0;
    }
  }
  @supports (-webkit-overflow-scrolling: touch) {
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .image[src$=".svg"] {
        width: calc(100% + 1px);
      }
    }
  }
  .show-for-sr {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
  }
  .headline {
    font-family: "Noto Sans Devanagari";
    font-weight: 400;
  }
  .section-fit {
    max-width: 400px;
  }
  :root {
    --section-relative-margin: 0 auto;
  }
  .section-relative {
    position: relative;
    margin: 0 auto;
  }
  .js-text-scaling .section-relative {
    margin: var(--section-relative-margin);
  }
  .section-inner {
    height: 100%;
  }
  #page-block-eex4ic3yqa4 {
    height: 36.5625rem;
    max-width: 400px;
  }
  #page-block-eex4ic3yqa4 .section-holder-border {
    border: 0;
  }
  #page-block-eex4ic3yqa4 .section-block {
    background: rgb(242, 244, 246);
    height: 36.5625rem;
  }
  #page-block-eex4ic3yqa4 .section-holder-overlay {
    display: none;
  }
  #element-484 {
    top: 1.9375rem;
    left: 9rem;
    height: 6.6875rem;
    width: 6.9375rem;
    z-index: 69;
  }
  #element-737 {
    top: 16.9375rem;
    left: 1.25rem;
    height: 19.6875rem;
    width: 22.5rem;
    z-index: 80;
  }
  #element-2 {
    top: 10.1875rem;
    left: 1.1875rem;
    height: 4.875rem;
    width: 22.5rem;
    z-index: 4;
    color: #37465a;
    font-size: 1.3622rem;
    line-height: 1.65rem;
    text-align: center;
  }
  #element-2 .x_b796efc8 {
    text-align: center;
    line-height: 1.625rem;
    font-size: 1.3622rem;
  }
  #element-2 .x_be5cc131 {
    color: #111111;
  }
  #element-2 .x_1dad93f4 {
    color: #ff9f29;
  }
  #element-2 strong {
    font-weight: 700;
  }
  #element-705 {
    top: 49.9375rem;
    left: 6rem;
    height: 4.375rem;
    width: 12.6875rem;
    z-index: 79;
  }
  #page-block-9vzy99jex0e {
    height: 19.625rem;
    max-width: 400px;
  }
  #page-block-9vzy99jex0e .section-holder-border {
    border: 0;
  }
  #page-block-9vzy99jex0e .section-block {
    background: rgb(17, 17, 17);
    height: 19.625rem;
  }
  #page-block-9vzy99jex0e .section-holder-overlay {
    display: none;
  }
  #element-756 {
    top: 1.375rem;
    left: 4.5625rem;
    height: 2rem;
    width: 15.8125rem;
    z-index: 15;
    color: #37465a;
    font-size: 1.2384rem;
    line-height: 2rem;
    text-align: center;
  }
  #element-756 .x_06cb0376 {
    text-align: center;
    line-height: 2rem;
    font-size: 1.2384rem;
  }
  #element-756 .x_f2074b6c {
    color: #ffffff;
  }
  #element-756 strong {
    font-weight: 700;
  }
  #element-763 {
    top: 6.75rem;
    left: 2.9375rem;
    height: 11rem;
    width: 19.0625rem;
    z-index: 13;
  }
  .circle {
    border-radius: 50%;
  }
  .shape {
    height: inherit;
  }
  .line-horizontal {
    height: 0.625rem;
  }
  .line-vertical {
    height: 100%;
    margin-right: 0.625rem;
  }
  [class*="line-"] {
    box-sizing: content-box;
  }
  #element-763 .shape {
    border: 0.0625rem dashed #ff9f29;
    border-radius: 0.375rem 0.375rem 0.375rem 0.375rem;
    background: none;
  }
  #element-768 {
    top: 4.75rem;
    left: 8.75rem;
    height: 6.5625rem;
    width: 7.3125rem;
    z-index: 83;
  }
  #element-768 .shape {
    border: 0.0625rem dashed #ff9f29;
    border-radius: 0.375rem 0.375rem 0.375rem 0.375rem;
    background: none;
  }
  #element-767 {
    top: 4.75rem;
    left: 0.8125rem;
    height: 6.5625rem;
    width: 7.25rem;
    z-index: 82;
  }
  #element-767 .shape {
    border: 0.0625rem dashed #ff9f29;
    border-radius: 0.375rem 0.375rem 0.375rem 0.375rem;
    background: none;
  }
  #element-769 {
    top: 4.75rem;
    left: 16.8125rem;
    height: 6.5625rem;
    width: 7.25rem;
    z-index: 84;
  }
  #element-769 .shape {
    border: 0.0625rem dashed #ff9f29;
    border-radius: 0.375rem 0.375rem 0.375rem 0.375rem;
    background: none;
  }
  #element-757 {
    top: 6.5625rem;
    left: 1.5625rem;
    height: 1.5rem;
    width: 5.75rem;
    z-index: 17;
    color: #37465a;
    font-size: 1.4861rem;
    line-height: 1.5rem;
    text-align: center;
  }
  #element-757 .x_86b935ec {
    text-align: center;
    line-height: 1.5rem;
    font-size: 1.4861rem;
  }
  #element-757 .x_f2074b6c {
    color: #ffffff;
  }
  #element-757 .x_92851e38 {
    text-align: left;
    background-color: transparent;
    font-style: inherit;
  }
  #element-757 strong {
    font-weight: 700;
  }
  #element-761 {
    top: 6.5625rem;
    left: 9.3125rem;
    height: 1.5rem;
    width: 6.125rem;
    z-index: 56;
    color: #37465a;
    font-size: 1.4861rem;
    line-height: 1.5rem;
    text-align: center;
  }
  #element-761 .x_86b935ec {
    text-align: center;
    line-height: 1.5rem;
    font-size: 1.4861rem;
  }
  #element-761 .x_f2074b6c {
    color: #ffffff;
  }
  #element-761 strong {
    font-weight: 700;
  }
  #element-759 {
    top: 6.5625rem;
    left: 18.75rem;
    height: 1.5rem;
    width: 3.5625rem;
    z-index: 18;
    color: #37465a;
    font-size: 1.4861rem;
    line-height: 1.5rem;
    text-align: center;
  }
  #element-759 .x_86b935ec {
    text-align: center;
    line-height: 1.5rem;
    font-size: 1.4861rem;
  }
  #element-759 .x_f2074b6c {
    color: #ffffff;
  }
  #element-759 strong {
    font-weight: 700;
  }
  #element-760 {
    top: 8.625rem;
    left: 18.9375rem;
    height: 1.625rem;
    width: 3.25rem;
    z-index: 19;
    color: #37465a;
    font-size: 0.9907rem;
    line-height: 1.6rem;
    text-align: center;
  }
  #element-760 .x_dc68832a {
    text-align: center;
    line-height: 1.625rem;
    font-size: 0.9907rem;
  }
  #element-760 .x_f2074b6c {
    color: #ffffff;
  }
  #element-760 strong {
    font-weight: 700;
  }
  #element-762 {
    top: 8.625rem;
    left: 1.625rem;
    height: 1.625rem;
    width: 5.625rem;
    z-index: 57;
    color: #37465a;
    font-size: 0.9907rem;
    line-height: 1.6rem;
    text-align: center;
  }
  #element-762 .x_dc68832a {
    text-align: center;
    line-height: 1.625rem;
    font-size: 0.9907rem;
  }
  #element-762 .x_f2074b6c {
    color: #ffffff;
  }
  #element-762 strong {
    font-weight: 700;
  }
  #element-758 {
    top: 8.625rem;
    left: 10.125rem;
    height: 1.625rem;
    width: 4.4375rem;
    z-index: 14;
    color: #37465a;
    font-size: 0.9907rem;
    line-height: 1.6rem;
    text-align: center;
  }
  #element-758 .x_dc68832a {
    text-align: center;
    line-height: 1.625rem;
    font-size: 0.9907rem;
  }
  #element-758 .x_f2074b6c {
    color: #ffffff;
  }
  #element-758 strong {
    font-weight: 700;
  }
  #page-block-buh4ftm7fl8 {
    height: 36.6875rem;
    max-width: 400px;
  }
  #page-block-buh4ftm7fl8 .section-holder-border {
    border: 0;
  }
  #page-block-buh4ftm7fl8 .section-block {
    background: rgb(244, 242, 242);
    height: 36.6875rem;
  }
  #page-block-buh4ftm7fl8 .section-holder-overlay {
    display: none;
  }
  #element-678 {
    top: 0.75rem;
    left: 1.3125rem;
    height: 13.75rem;
    width: 11.8125rem;
    z-index: 51;
  }
  #element-678 .cropped {
    background: url(../images/62508047-0-housewife-removebg-p.webp) -6.375rem -5.125rem /
      28.25rem 18.875rem;
  }
  #element-384 {
    top: 10.625rem;
    left: 1.8125rem;
    height: 8.875rem;
    width: 21.75rem;
    z-index: 52;
  }
  #element-384 .shape {
    border: 0;
    border-radius: 0.75rem 0.75rem 0.75rem 0.75rem;
    background: rgb(255, 159, 41);
  }
  #element-148 {
    top: 11.75rem;
    left: 3.375rem;
    height: 3.25rem;
    width: 19.375rem;
    z-index: 53;
    color: #37465a;
    font-size: 1.3622rem;
    line-height: 1.65rem;
    text-align: left;
  }
  #element-148 .x_33879449 {
    text-align: left;
    line-height: 1.625rem;
    font-size: 1.3622rem;
  }
  #element-148 .x_f2074b6c {
    color: #ffffff;
  }
  #element-148 .x_686005f3 {
    text-align: left;
    background-color: transparent;
    font-weight: inherit;
  }
  #element-148 strong {
    font-weight: 700;
  }
  #element-155 {
    top: 15.375rem;
    left: 3.4375rem;
    height: 1.8125rem;
    width: 15.6875rem;
    z-index: 54;
    color: #37465a;
    font-size: 1.1146rem;
    line-height: 1.8rem;
    text-align: left;
    font-weight: 400;
  }
  #element-155 .x_8c071feb {
    text-align: left;
    line-height: 1.8125rem;
    font-size: 1.1146rem;
  }
  #element-155 .x_92851e38 {
    text-align: left;
    background-color: transparent;
    font-style: inherit;
  }
  #element-155 .x_66d2bef0 {
    color: #fdfdfd;
  }
  #element-155 strong {
    font-weight: 700;
  }
  #element-155.paragraph {
    font-weight: 400;
  }
  #element-325 {
    top: 17.3125rem;
    left: 3.4375rem;
    height: 1.625rem;
    width: 15.6875rem;
    z-index: 55;
    color: #37465a;
    font-size: 0.9907rem;
    line-height: 1.6rem;
    text-align: left;
    font-weight: 400;
  }
  #element-325 .x_fae6260b {
    text-align: left;
    line-height: 1.625rem;
    font-size: 0.9907rem;
  }
  #element-325 .x_f2074b6c {
    color: #ffffff;
  }
  #element-325 strong {
    font-weight: 700;
  }
  #element-325.paragraph {
    font-weight: 400;
  }
  #element-441 {
    top: 20.5rem;
    left: 14rem;
    height: 14.5rem;
    width: 10.9375rem;
    z-index: 62;
  }
  #element-441 .cropped {
    background: url(../images/62476064-0-family-on-bike-remov.png) -14.6875rem -3.6875rem /
      26.875rem 18.125rem;
  }
  #element-449 {
    top: 24.1875rem;
    left: 1.125rem;
    height: 10.8125rem;
    width: 19.5rem;
    z-index: 58;
  }
  #element-449 .shape {
    border: 0;
    border-radius: 0.75rem 0.75rem 0.75rem 0.75rem;
    background: rgb(255, 159, 41);
  }
  #element-450 {
    top: 25.375rem;
    left: 2.3125rem;
    height: 4.875rem;
    width: 13.9375rem;
    z-index: 59;
    color: #37465a;
    font-size: 1.3622rem;
    line-height: 1.65rem;
    text-align: left;
  }
  #element-450 .x_33879449 {
    text-align: left;
    line-height: 1.625rem;
    font-size: 1.3622rem;
  }
  #element-450 .x_f2074b6c {
    color: #ffffff;
  }
  #element-450 strong {
    font-weight: 700;
  }
  #element-451 {
    top: 30.875rem;
    left: 2.3125rem;
    height: 1.8125rem;
    width: 6.6875rem;
    z-index: 60;
    color: #37465a;
    font-size: 1.1146rem;
    line-height: 1.8rem;
    text-align: left;
    font-weight: 400;
  }
  #element-451 .x_8c071feb {
    text-align: left;
    line-height: 1.8125rem;
    font-size: 1.1146rem;
  }
  #element-451 .x_f2074b6c {
    color: #ffffff;
  }
  #element-451 strong {
    font-weight: 700;
  }
  #element-451.paragraph {
    font-weight: 400;
  }
  #element-452 {
    top: 32.8125rem;
    left: 2.3125rem;
    height: 1.625rem;
    width: 15.125rem;
    z-index: 61;
    color: #37465a;
    font-size: 0.9907rem;
    line-height: 1.6rem;
    text-align: left;
    font-weight: 400;
  }
  #element-452 .x_fae6260b {
    text-align: left;
    line-height: 1.625rem;
    font-size: 0.9907rem;
  }
  #element-452 .x_f2074b6c {
    color: #ffffff;
  }
  #element-452 strong {
    font-weight: 700;
  }
  #element-452.paragraph {
    font-weight: 400;
  }
  #page-block-ne7ha3gxh8l {
    height: 6.5rem;
    max-width: 400px;
  }
  #page-block-ne7ha3gxh8l .section-holder-border {
    border: 0;
  }
  #page-block-ne7ha3gxh8l .section-block {
    background: rgb(17, 17, 17);
    height: 6.5rem;
  }
  #page-block-ne7ha3gxh8l .section-holder-overlay {
    display: none;
  }
  #element-680 {
    top: 1.5625rem;
    left: 0.75rem;
    height: 3.5rem;
    width: 23.4375rem;
    z-index: 77;
    color: #37465a;
    font-size: 1.2384rem;
    line-height: 1.75rem;
    text-align: left;
  }
  #element-680 .x_b558b569 {
    text-align: left;
    line-height: 1.75rem;
    font-size: 1.2384rem;
  }
  #element-680 .x_f2074b6c {
    color: #ffffff;
  }
  #element-680 .x_1dad93f4 {
    color: #ff9f29;
  }
  #element-680 strong {
    font-weight: 700;
  }
  #page-block-laxcqvw96w8 {
    height: 175.6875rem;
    max-width: 100%;
  }
  #page-block-laxcqvw96w8 .section-holder-border {
    border: 0;
  }
  #page-block-laxcqvw96w8 .section-block {
    background: rgb(255, 255, 255);
    height: 175.6875rem;
  }
  #page-block-laxcqvw96w8 .section-holder-overlay {
    display: none;
  }
  #element-337 {
    top: 2.5rem;
    left: 1.25rem;
    height: 1.8125rem;
    width: 22.5rem;
    z-index: 11;
    color: #37465a;
    font-size: 1.7957rem;
    line-height: 1.8125rem;
    text-align: center;
  }
  #element-337 .x_daf2fc4f {
    text-align: center;
    line-height: 1.8125rem;
    font-size: 1.7957rem;
  }
  #element-337 .x_1dad93f4 {
    color: #ff9f29;
  }
  #element-337 strong {
    font-weight: 700;
  }
  #element-651 {
    top: 53.75rem;
    left: 1.25rem;
    height: 32.625rem;
    width: 22.5rem;
    z-index: 76;
  }
  #element-650 {
    top: 11.8125rem;
    left: 2.1875rem;
    height: 34.0625rem;
    width: 20.625rem;
    z-index: 75;
  }
  #element-777 {
    top: 49.1875rem;
    left: 4.25rem;
    height: 3.3125rem;
    width: 3.3125rem;
    z-index: 89;
  }
  #element-777 .shape {
    border: 0;
    background: rgb(255, 159, 41);
  }
  #element-778 {
    top: 50rem;
    left: 4.25rem;
    height: 1.625rem;
    width: 3.3125rem;
    z-index: 90;
    color: #37465a;
    font-size: 0.9907rem;
    line-height: 1.6rem;
    text-align: center;
  }
  #element-778 .x_dc68832a {
    text-align: center;
    line-height: 1.625rem;
    font-size: 0.9907rem;
  }
  #element-778 .x_be5cc131 {
    color: #111111;
  }
  #element-778 strong {
    font-weight: 700;
  }
  #element-772 {
    top: 6.75rem;
    left: 2.5625rem;
    height: 3.3125rem;
    width: 3.3125rem;
    z-index: 86;
  }
  #element-772 .shape {
    border: 0;
    background: rgb(255, 159, 41);
  }
  #element-773 {
    top: 7.5625rem;
    left: 2.5625rem;
    height: 1.625rem;
    width: 3.3125rem;
    z-index: 87;
    color: #37465a;
    font-size: 0.9907rem;
    line-height: 1.6rem;
    text-align: center;
  }
  #element-773 .x_dc68832a {
    text-align: center;
    line-height: 1.625rem;
    font-size: 0.9907rem;
  }
  #element-773 .x_be5cc131 {
    color: #111111;
  }
  #element-773 strong {
    font-weight: 700;
  }
  #element-775 {
    top: 49.1875rem;
    left: 8.5625rem;
    height: 3.625rem;
    width: 12.125rem;
    z-index: 88;
    color: #37465a;
    font-size: 1.1146rem;
    line-height: 1.8rem;
    text-align: left;
  }
  #element-775 .x_8c071feb {
    text-align: left;
    line-height: 1.8125rem;
    font-size: 1.1146rem;
  }
  #element-775 strong {
    font-weight: 700;
  }
  #element-774 {
    top: 6.9375rem;
    left: 7.0625rem;
    height: 3.125rem;
    width: 15.3125rem;
    z-index: 85;
    color: #37465a;
    font-size: 1.1146rem;
    line-height: 1.575rem;
    text-align: left;
  }
  #element-774 .x_9a9d3d17 {
    text-align: left;
    line-height: 1.5625rem;
    font-size: 1.1146rem;
  }
  #element-774 strong {
    font-weight: 700;
  }
  #element-643 {
    top: 96.0625rem;
    left: 4.25rem;
    height: 34.5625rem;
    width: 16.375rem;
    z-index: 73;
  }
  #element-645 {
    top: 139.6875rem;
    left: 2.1875rem;
    height: 34.5rem;
    width: 20.625rem;
    z-index: 74;
  }
  #element-468 {
    top: 90.8125rem;
    left: 2.25rem;
    height: 3.3125rem;
    width: 3.3125rem;
    z-index: 64;
  }
  #element-468 .shape {
    border: 0;
    background: rgb(255, 159, 41);
  }
  #element-469 {
    top: 91.625rem;
    left: 2.25rem;
    height: 1.625rem;
    width: 3.3125rem;
    z-index: 65;
    color: #37465a;
    font-size: 0.9907rem;
    line-height: 1.6rem;
    text-align: center;
  }
  #element-469 .x_dc68832a {
    text-align: center;
    line-height: 1.625rem;
    font-size: 0.9907rem;
  }
  #element-469 .x_be5cc131 {
    color: #111111;
  }
  #element-469 strong {
    font-weight: 700;
  }
  #element-466 {
    top: 90.8125rem;
    left: 6.6875rem;
    height: 3.625rem;
    width: 16rem;
    z-index: 63;
    color: #37465a;
    font-size: 1.1146rem;
    line-height: 1.8rem;
    text-align: left;
  }
  #element-466 .x_8c071feb {
    text-align: left;
    line-height: 1.8125rem;
    font-size: 1.1146rem;
  }
  #element-466 strong {
    font-weight: 700;
  }
  #element-473 {
    top: 134.4375rem;
    left: 3.1875rem;
    height: 3.3125rem;
    width: 3.3125rem;
    z-index: 67;
  }
  #element-473 .shape {
    border: 0;
    background: rgb(255, 159, 41);
  }
  #element-474 {
    top: 135.25rem;
    left: 3.1875rem;
    height: 1.625rem;
    width: 3.3125rem;
    z-index: 68;
    color: #37465a;
    font-size: 0.9907rem;
    line-height: 1.6rem;
    text-align: center;
  }
  #element-474 .x_dc68832a {
    text-align: center;
    line-height: 1.625rem;
    font-size: 0.9907rem;
  }
  #element-474 .x_be5cc131 {
    color: #111111;
  }
  #element-474 strong {
    font-weight: 700;
  }
  #element-471 {
    top: 134.4375rem;
    left: 7.5rem;
    height: 3.625rem;
    width: 14.3125rem;
    z-index: 66;
    color: #37465a;
    font-size: 1.1146rem;
    line-height: 1.8rem;
    text-align: left;
  }
  #element-471 .x_8c071feb {
    text-align: left;
    line-height: 1.8125rem;
    font-size: 1.1146rem;
  }
  #element-471 strong {
    font-weight: 700;
  }
  #page-block-05m97vylt1m {
    height: 32.5625rem;
    max-width: 400px;
  }
  #page-block-05m97vylt1m .section-holder-border {
    border: 0;
  }
  #page-block-05m97vylt1m .section-block {
    background: rgb(255, 255, 255);
    height: 32.5625rem;
  }
  #page-block-05m97vylt1m .section-holder-overlay {
    display: none;
  }
  #element-779 {
    top: 2rem;
    left: 0.8125rem;
    height: 0;
    width: 23.4375rem;
    z-index: 50;
    color: #37465a;
    font-size: 1.6099rem;
    line-height: 2.275rem;
    text-align: center;
  }
  #element-779 .x_47b745f5 {
    text-align: center;
    line-height: 2.25rem;
    font-size: 1.6099rem;
  }
  #element-779 .x_1dad93f4 {
    color: #ff9f29;
  }
  #element-779 strong {
    font-weight: 700;
  }
  #page-block-ppa8syz3e5 {
    height: 24rem;
    max-width: 400px;
  }
  #page-block-ppa8syz3e5 .section-holder-border {
    border: 0;
  }
  #page-block-ppa8syz3e5 .section-block {
    background: rgb(17, 17, 17);
    height: 24rem;
  }
  #page-block-ppa8syz3e5 .section-holder-overlay {
    display: none;
  }
  #element-755 {
    top: 0.875rem;
    left: 0.0625rem;
    height: 22.5rem;
    width: 25rem;
    z-index: 81;
  }
  #page-block-euq3gv5pn19 {
    height: 77.25rem;
    max-width: 400px;
  }
  #page-block-euq3gv5pn19 .section-holder-border {
    border: 0;
  }
  #page-block-euq3gv5pn19 .section-block {
    background: rgb(242, 244, 246);
    height: 77.25rem;
  }
  #page-block-euq3gv5pn19 .section-holder-overlay {
    display: none;
  }
  #element-228 {
    top: 2.5rem;
    left: 1.25rem;
    height: 3.875rem;
    width: 22.5rem;
    z-index: 21;
    color: #37465a;
    font-size: 1.6099rem;
    line-height: 1.95rem;
    text-align: center;
  }
  #element-228 .x_64e665fe {
    text-align: center;
    line-height: 1.9375rem;
    font-size: 1.6099rem;
  }
  #element-228 .x_be5cc131 {
    color: #111111;
  }
  #element-228 .x_1dad93f4 {
    color: #ff9f29;
  }
  #element-228 strong {
    font-weight: 700;
  }
  #element-226 {
    top: 7.625rem;
    left: 2.875rem;
    height: 21.125rem;
    width: 19.1875rem;
    z-index: 22;
  }
  #element-226 .shape {
    border: 0;
    border-radius: 0.75rem 0.75rem 0.75rem 0.75rem;
    background: rgb(255, 255, 255);
  }
  #element-238 {
    top: 9.5rem;
    left: 8.6875rem;
    height: 7.6875rem;
    width: 7.6875rem;
    z-index: 25;
  }
  #element-238 .shape {
    border: 0.0625rem solid #fdfdfd;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mM89h8AApEBx2iaqpQAAAAASUVORK5CYII=)
      repeat rgb(240, 243, 245) 50% 50% / cover;
  }
  #element-233 {
    top: 18.5625rem;
    left: 4.125rem;
    height: 3.625rem;
    width: 16.6875rem;
    z-index: 23;
    color: #37465a;
    font-size: 1.1146rem;
    line-height: 1.8rem;
    text-align: center;
  }
  #element-233 .x_e6507cf2 {
    text-align: center;
    line-height: 1.8125rem;
    font-size: 1.1146rem;
  }
  #element-233 .x_be5cc131 {
    color: #111111;
  }
  #element-233 .x_686005f3 {
    text-align: left;
    background-color: transparent;
    font-weight: inherit;
  }
  #element-233 strong {
    font-weight: 700;
  }
  #element-234 {
    top: 24.3125rem;
    left: 4.125rem;
    height: 3.25rem;
    width: 16.6875rem;
    z-index: 24;
    color: #37465a;
    font-size: 0.9907rem;
    line-height: 1.6rem;
    text-align: center;
  }
  #element-234 .x_dc68832a {
    text-align: center;
    line-height: 1.625rem;
    font-size: 0.9907rem;
  }
  #element-234 .x_1dad93f4 {
    color: #ff9f29;
  }
  #element-234 strong {
    font-weight: 700;
  }
  #element-245 {
    top: 30.625rem;
    left: 2.875rem;
    height: 21.125rem;
    width: 19.1875rem;
    z-index: 28;
  }
  #element-245 .shape {
    border: 0;
    border-radius: 0.75rem 0.75rem 0.75rem 0.75rem;
    background: rgb(255, 255, 255);
  }
  #element-248 {
    top: 32.5rem;
    left: 8.6875rem;
    height: 7.6875rem;
    width: 7.6875rem;
    z-index: 31;
  }
  #element-248 .shape {
    border: 0.0625rem solid #fdfdfd;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mM89h8AApEBx2iaqpQAAAAASUVORK5CYII=)
      repeat rgb(240, 243, 245) 50% 50% / cover;
  }
  #element-246 {
    top: 41.5625rem;
    left: 3.5rem;
    height: 5.4375rem;
    width: 17.9375rem;
    z-index: 29;
    color: #37465a;
    font-size: 1.1146rem;
    line-height: 1.8rem;
    text-align: center;
  }
  #element-246 .x_e6507cf2 {
    text-align: center;
    line-height: 1.8125rem;
    font-size: 1.1146rem;
  }
  #element-246 .x_be5cc131 {
    color: #111111;
  }
  #element-246 .x_686005f3 {
    text-align: left;
    background-color: transparent;
    font-weight: inherit;
  }
  #element-246 strong {
    font-weight: 700;
  }
  #element-247 {
    top: 47.3125rem;
    left: 4.125rem;
    height: 3.25rem;
    width: 16.6875rem;
    z-index: 30;
    color: #37465a;
    font-size: 0.9907rem;
    line-height: 1.6rem;
    text-align: center;
  }
  #element-247 .x_dc68832a {
    text-align: center;
    line-height: 1.625rem;
    font-size: 0.9907rem;
  }
  #element-247 .x_1dad93f4 {
    color: #ff9f29;
  }
  #element-247 strong {
    font-weight: 700;
  }
  #element-252 {
    top: 53.625rem;
    left: 2.875rem;
    height: 21.125rem;
    width: 19.1875rem;
    z-index: 32;
  }
  #element-252 .shape {
    border: 0;
    border-radius: 0.75rem 0.75rem 0.75rem 0.75rem;
    background: rgb(255, 255, 255);
  }
  #element-258 {
    top: 55.5rem;
    left: 8.6875rem;
    height: 7.6875rem;
    width: 7.6875rem;
    z-index: 35;
  }
  #element-258 .shape {
    border: 0.0625rem solid #fdfdfd;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mM89h8AApEBx2iaqpQAAAAASUVORK5CYII=)
      repeat rgb(240, 243, 245) 56% 35% / cover;
  }
  #element-254 {
    top: 64.5625rem;
    left: 4.125rem;
    height: 3.625rem;
    width: 16.6875rem;
    z-index: 33;
    color: #37465a;
    font-size: 1.1146rem;
    line-height: 1.8rem;
    text-align: center;
  }
  #element-254 .x_e6507cf2 {
    text-align: center;
    line-height: 1.8125rem;
    font-size: 1.1146rem;
  }
  #element-254 .x_7b2817bf {
    color: #000000;
  }
  #element-254 .x_686005f3 {
    text-align: left;
    background-color: transparent;
    font-weight: inherit;
  }
  #element-254 strong {
    font-weight: 700;
  }
  #element-256 {
    top: 70.3125rem;
    left: 4.125rem;
    height: 3.25rem;
    width: 16.6875rem;
    z-index: 34;
    color: #37465a;
    font-size: 0.9907rem;
    line-height: 1.6rem;
    text-align: center;
  }
  #element-256 .x_dc68832a {
    text-align: center;
    line-height: 1.625rem;
    font-size: 0.9907rem;
  }
  #element-256 .x_1dad93f4 {
    color: #ff9f29;
  }
  #element-256 strong {
    font-weight: 700;
  }
  #page-block-edqepzfrovn {
    height: 21.5rem;
    max-width: 400px;
  }
  #page-block-edqepzfrovn .section-holder-border {
    border: 0;
  }
  #page-block-edqepzfrovn .section-block {
    background: rgb(255, 255, 255);
    height: 21.5rem;
  }
  #page-block-edqepzfrovn .section-holder-overlay {
    display: none;
  }
  #element-210 {
    top: 2.5rem;
    left: 1.25rem;
    height: 4.5rem;
    width: 22.5rem;
    z-index: 7;
    color: #37465a;
    font-size: 1.6099rem;
    line-height: 2.275rem;
    text-align: center;
  }
  #element-210 .x_47b745f5 {
    text-align: center;
    line-height: 2.25rem;
    font-size: 1.6099rem;
  }
  #element-210 .x_be5cc131 {
    color: #111111;
  }
  #element-210 .x_1dad93f4 {
    color: #ff9f29;
  }
  #element-210 strong {
    font-weight: 700;
  }
  #element-214 {
    top: 8.25rem;
    left: 1.25rem;
    height: 11.1875rem;
    width: 22.5rem;
    z-index: 20;
  }
  .video-holder {
    height: 100%;
    overflow: hidden;
    position: relative;
  }
  .video-holder-animoto {
    background-color: #000;
  }
  .video-animoto {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    margin: auto;
  }
  .video-iframe {
    position: absolute;
  }
  .video-overlay:hover {
    opacity: 1;
  }
  .video-holder-helpers {
    transition: opacity 0.15s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 14px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .video-overlay {
    background-color: rgba(31, 59, 82, 0.8);
    color: #ffffff;
    opacity: 0;
    z-index: 1;
  }
  .warning-text {
    margin-top: 10px;
    font-size: 13px;
  }
  .warning-img {
    width: 25px;
  }
  .fake-video {
    background: #ffffff;
  }
  .fake-play {
    opacity: 0.8;
  }
  .video-overlay:hover ~ .fake-play {
    opacity: 0;
  }
  element-214 iframe {
    width: 360px;
    height: 179px;
  }
  #element-212 {
    top: 41.5625rem;
    left: 9.9375rem;
    height: 5.125rem;
    width: 5.125rem;
    z-index: 8;
  }
  #page-block-rprp5g5gpym {
    height: 9.5rem;
    max-width: 400px;
  }
  #page-block-rprp5g5gpym .section-holder-border {
    border: 0;
  }
  #page-block-rprp5g5gpym .section-block {
    background: rgb(251, 251, 251);
    height: 9.5rem;
  }
  #page-block-rprp5g5gpym .section-holder-overlay {
    display: none;
  }
  #element-109 {
    top: 2.1875rem;
    left: 1.25rem;
    height: 1.625rem;
    width: 22.5rem;
    z-index: 6;
    color: #37465a;
    font-size: 1.6099rem;
    line-height: 1.625rem;
    text-align: center;
  }
  #element-109 .x_f82204f0 {
    text-align: center;
    line-height: 1.625rem;
    font-size: 1.6099rem;
  }
  #element-109 .x_be5cc131 {
    color: #111111;
  }
  #element-109 .x_1dad93f4 {
    color: #ff9f29;
  }
  #element-109 strong {
    font-weight: 700;
  }
  #element-261 {
    top: 5.3125rem;
    left: 1.25rem;
    height: 3.375rem;
    width: 22.5rem;
    z-index: 36;
    color: #37465a;
    font-size: 1.1765rem;
    line-height: 1.6625rem;
    text-align: center;
  }
  #element-261 .x_66d76769 {
    text-align: center;
    line-height: 1.6875rem;
    font-size: 1.1765rem;
  }
  #element-261 .x_be5cc131 {
    color: #111111;
  }
  #element-261 .x_1dad93f4 {
    color: #ff9f29;
  }
  #element-261 strong {
    font-weight: 700;
  }
  #page-block-xtxeeaj9qhq {
    height: 85rem;
    max-width: 400px;
  }
  #page-block-xtxeeaj9qhq .section-holder-border {
    border: 0;
  }
  #page-block-xtxeeaj9qhq .section-block {
    background: rgb(17, 17, 17);
    height: 85rem;
  }
  #page-block-xtxeeaj9qhq .section-holder-overlay {
    display: none;
  }
  #element-178 {
    top: 1.625rem;
    left: 1.25rem;
    height: 15.0625rem;
    width: 22.5rem;
    z-index: 10;
  }
  #element-178 .shape {
    border: 0;
    border-radius: 0.75rem 0.75rem 0.75rem 0.75rem;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mM89h8AApEBx2iaqpQAAAAASUVORK5CYII=)
      repeat rgb(255, 255, 255) 50% 50% / cover;
  }
  #element-177 {
    top: 18.0625rem;
    left: 1.3125rem;
    height: 10rem;
    width: 12.1875rem;
    z-index: 9;
  }
  #element-177 .shape {
    border: 0;
    border-radius: 0.375rem 0.375rem 0.375rem 0.375rem;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mM89h8AApEBx2iaqpQAAAAASUVORK5CYII=)
      repeat rgb(255, 255, 255) 50% 50% / cover;
  }
  #element-179 {
    top: 18.0625rem;
    left: 15rem;
    height: 10rem;
    width: 8.75rem;
    z-index: 12;
  }
  #element-179 .shape {
    border: 0;
    border-radius: 0.375rem 0.375rem 0.375rem 0.375rem;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mM89h8AApEBx2iaqpQAAAAASUVORK5CYII=)
      repeat rgb(255, 255, 255) 50% 50% / cover;
  }
  #page-block-2rymn9d97la {
    height: 58.3125rem;
    max-width: 100%;
  }
  #page-block-2rymn9d97la .section-holder-border {
    border: 0;
  }
  #page-block-2rymn9d97la .section-block {
    background: rgb(255, 255, 255);
    height: 58.3125rem;
  }
  #page-block-2rymn9d97la .section-holder-overlay {
    display: none;
  }
  #element-582 {
    top: 2.5rem;
    left: 1.25rem;
    height: 1.625rem;
    width: 22.5rem;
    z-index: 27;
    color: #37465a;
    font-size: 1.6099rem;
    line-height: 1.625rem;
    text-align: center;
  }
  #element-582 .x_f82204f0 {
    text-align: center;
    line-height: 1.625rem;
    font-size: 1.6099rem;
  }
  #element-582 .x_1dad93f4 {
    color: #ff9f29;
  }
  #element-582 .x_be5cc131 {
    color: #111111;
  }
  #element-582 strong {
    font-weight: 700;
  }
  #element-597 {
    top: 9.5rem;
    left: 0;
    height: 17.0625rem;
    width: 24.9375rem;
    z-index: 71;
  }
  #element-5971 {
    top: 21rem;
    left: 0;
    height: 17.0625rem;
    width: 24.9375rem;
    z-index: 73;
  }
  #element-598 {
    top: 36.75rem;
    left: 0.0625rem;
    height: 17.0625rem;
    width: 24.9375rem;
    z-index: 72;
  }
  #element-596 {
    top: 5.375rem;
    left: 0.0625rem;
    height: 17.0625rem;
    width: 24.9375rem;
    z-index: 70;
  }
  #page-block-ej3l1zy2k3t {
    height: 101.375rem;
    max-width: 100%;
  }
  #page-block-ej3l1zy2k3t .section-holder-border {
    border: 0;
  }
  #page-block-ej3l1zy2k3t .section-block {
    background: rgb(255, 255, 255);
    height: 101.375rem;
  }
  #page-block-ej3l1zy2k3t .section-holder-overlay {
    display: none;
  }
  #element-262 {
    top: 2.5rem;
    left: 1.25rem;
    height: 1.625rem;
    width: 22.5rem;
    z-index: 26;
    color: #37465a;
    font-size: 1.6099rem;
    line-height: 1.625rem;
    text-align: center;
  }
  #element-262 .x_f82204f0 {
    text-align: center;
    line-height: 1.625rem;
    font-size: 1.6099rem;
  }
  #element-262 .x_1dad93f4 {
    color: #ff9f29;
  }
  #element-262 .x_be5cc131 {
    color: #111111;
  }
  #element-262 strong {
    font-weight: 700;
  }
  #element-263 {
    top: 6rem;
    left: 3.125rem;
    height: 27.625rem;
    width: 18.75rem;
    z-index: 37;
  }
  #element-263 .shape {
    border: 0;
    border-radius: 0 0 0.375rem 0.375rem;
    background: rgb(240, 243, 245);
  }
  #element-266 {
    top: 6rem;
    left: 3.125rem;
    height: 16.25rem;
    width: 18.75rem;
    z-index: 39;
  }
  #element-266 .shape {
    border: 0;
    border-radius: 0.375rem 0.375rem 0 0;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mM89h8AApEBx2iaqpQAAAAASUVORK5CYII=)
      repeat rgb(255, 255, 255) 50% 50% / cover;
  }
  #element-265 {
    top: 23.75rem;
    left: 4.75rem;
    height: 4.6875rem;
    width: 15.5rem;
    z-index: 38;
    color: #37465a;
    font-size: 1.1146rem;
    line-height: 1.575rem;
    text-align: center;
  }
  #element-265 .x_bcbce646 {
    text-align: center;
    line-height: 1.5625rem;
    font-size: 1.1146rem;
  }
  #element-265 .x_be5cc131 {
    color: #111111;
  }
  #element-265 strong {
    font-weight: 700;
  }
  #element-279 {
    top: 29.4375rem;
    left: 8.5rem;
    height: 3.125rem;
    width: 8rem;
    z-index: 38;
  }
  #element-287 {
    top: 35.5rem;
    left: 3.125rem;
    height: 20.625rem;
    width: 18.75rem;
    z-index: 42;
  }
  #element-287 .shape {
    border: 0;
    border-radius: 0.375rem 0.375rem 0 0;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mM89h8AApEBx2iaqpQAAAAASUVORK5CYII=)
      repeat rgb(255, 255, 255) 50% 50% / cover;
  }
  #element-285 {
    top: 57.6875rem;
    left: 4.375rem;
    height: 4.6875rem;
    width: 16.1875rem;
    z-index: 41;
    color: #37465a;
    font-size: 1.1146rem;
    line-height: 1.575rem;
    text-align: center;
  }
  #element-285 .x_bcbce646 {
    text-align: center;
    line-height: 1.5625rem;
    font-size: 1.1146rem;
  }
  #element-285 .x_be5cc131 {
    color: #111111;
  }
  #element-285 strong {
    font-weight: 700;
  }
  #element-283 {
    top: 35.5rem;
    left: 3.125rem;
    height: 32rem;
    width: 18.75rem;
    z-index: 40;
  }
  #element-283 .shape {
    border: 0;
    border-radius: 0 0 0.375rem 0.375rem;
    background: rgb(240, 243, 245);
  }
  #element-289 {
    top: 63.5625rem;
    left: 8.5rem;
    height: 3.125rem;
    width: 8rem;
    z-index: 44;
  }
  #element-289 .cropped {
    background: url(../images/62468546-0-media2logo.png) -1.375rem
      0 / 10.75rem 3.125rem;
  }
  #element-319 {
    top: 71.25rem;
    left: 3.125rem;
    height: 16.25rem;
    width: 18.75rem;
    z-index: 47;
  }
  #element-319 .shape {
    border: 0;
    border-radius: 0.375rem 0.375rem 0 0;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mM89h8AApEBx2iaqpQAAAAASUVORK5CYII=)
      repeat rgb(255, 255, 255) 50% 50% / cover;
  }
  #element-317 {
    top: 89rem;
    left: 4.4375rem;
    height: 4.6875rem;
    width: 16.1875rem;
    z-index: 46;
    color: #37465a;
    font-size: 1.1146rem;
    line-height: 1.575rem;
    text-align: center;
  }
  #element-317 .x_bcbce646 {
    text-align: center;
    line-height: 1.5625rem;
    font-size: 1.1146rem;
  }
  #element-317 .x_be5cc131 {
    color: #111111;
  }
  #element-317 strong {
    font-weight: 700;
  }
  #element-314 {
    top: 71.25rem;
    left: 3.125rem;
    height: 27.625rem;
    width: 18.75rem;
    z-index: 45;
  }
  #element-314 .shape {
    border: 0;
    border-radius: 0 0 0.375rem 0.375rem;
    background: rgb(240, 243, 245);
  }
  #element-321 {
    top: 94.375rem;
    left: 8.5rem;
    height: 3.125rem;
    width: 8rem;
    z-index: 49;
  }
  #element-321 .cropped {
    background: url(../images/62468548-0-media1logo.png) -1.375rem
      0 / 10.75rem 3.125rem;
  }
  #page-block-jkogznfscv {
    height: 8.3125rem;
    max-width: 400px;
  }
  #page-block-jkogznfscv .section-holder-border {
    border: 0;
  }
  #page-block-jkogznfscv .section-block {
    background: rgb(30, 30, 30);
    height: 8.3125rem;
  }
  #page-block-jkogznfscv .section-holder-overlay {
    display: none;
  }
  #element-781 {
    top: 2.0625rem;
    left: 1.1875rem;
    height: 1.375rem;
    width: 22.5rem;
    z-index: 91;
    color: #37465a;
    font-size: 0.9907rem;
    line-height: 1.4rem;
    text-align: center;
  }
  #element-781 .x_2c80ab57 {
    text-align: center;
    line-height: 1.375rem;
    font-size: 0.9907rem;
  }
  #element-781 .x_2b6404b1 {
    color: #f2f4f6;
  }
  #element-782 {
    top: 5rem;
    left: 1.25rem;
    height: 1.375rem;
    width: 22.5rem;
    z-index: 92;
    color: #37465a;
    font-size: 0.9907rem;
    line-height: 1.4rem;
    text-align: center;
  }
  #element-782 .x_2c80ab57 {
    text-align: center;
    line-height: 1.375rem;
    font-size: 0.9907rem;
  }
  #element-782 .x_2b6404b1 {
    color: #f2f4f6;
  }
  #element-783 {
    top: 7.9375rem;
    left: 1.1875rem;
    height: 1.375rem;
    width: 22.5rem;
    z-index: 93;
    color: #37465a;
    font-size: 0.9907rem;
    line-height: 1.4rem;
    text-align: center;
  }
  #element-783 .x_2c80ab57 {
    text-align: center;
    line-height: 1.375rem;
    font-size: 0.9907rem;
  }
  #element-783 .x_2b6404b1 {
    color: #f2f4f6;
  }
  #element-784 {
    top: 1.8125rem;
    left: 5.625rem;
    height: 2rem;
    width: 2rem;
    z-index: 94;
  }
  #element-785 {
    top: 2rem;
    left: 8.625rem;
    height: 1.625rem;
    width: 10.6875rem;
    z-index: 95;
    color: #37465a;
    font-size: 0.9907rem;
    line-height: 1.6rem;
    text-align: left;
  }
  #element-785 .x_fae6260b {
    text-align: left;
    line-height: 1.625rem;
    font-size: 0.9907rem;
  }
  #element-785 .x_2b6404b1 {
    color: #f2f4f6;
  }
  #element-75 {
    top: 5.0625rem;
    left: 1.1875rem;
    height: 1.25rem;
    width: 22.5rem;
    z-index: 5;
    color: #37465a;
    font-size: 0.8669rem;
    line-height: 1.225rem;
    text-align: center;
  }
  #element-75 .x_c5f0bf77 {
    text-align: center;
    line-height: 1.25rem;
    font-size: 0.8669rem;
  }
  #element-75 .x_76ffb0ab {
    color: #e8e8e8;
  }
  #page-block-wlj9406vt9 {
    height: 5.9375rem;
    max-width: 400px;
  }
  #page-block-wlj9406vt9 .section-holder-border {
    border: 0;
  }
  #page-block-wlj9406vt9 .section-block {
    background: rgb(32, 141, 0);
    height: 5.9375rem;
  }
  #page-block-wlj9406vt9 .section-holder-overlay {
    display: none;
  }
  #element-681 {
    top: 1.125rem;
    left: 12rem;
    height: 4.25rem;
    width: 12.3125rem;
    z-index: 78;
  }
  #element-432 {
    top: 1.875rem;
    left: 1.25rem;
    height: 2.75rem;
    width: 10.75rem;
    z-index: 16;
    color: #37465a;
    font-size: 0.9907rem;
    line-height: 1.4rem;
    text-align: left;
  }
  #element-432 .x_0a34168d {
    text-align: left;
    line-height: 1.375rem;
    font-size: 0.9907rem;
  }
  #element-432 .x_f2074b6c {
    color: #ffffff;
  }
  #element-432 strong {
    font-weight: 700;
  }
  @media screen and (max-width: 400px) {
    :root {
      font-size: 4vw;
    }
    :root.js-text-scaling {
      font-size: var(--mobile-font-size);
    }
  }
  @media screen and (min-width: 401px) and (max-width: 767px) {
    :root {
      font-size: 16px;
    }
    :root.js-text-scaling {
      font-size: var(--default-font-size);
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1200px) {
    :root {
      font-size: 1.33vw;
    }
  }
  @media screen and (max-width: 767px) {
    .hidden-mobile {
      display: none;
    }
  }
  @media screen and (min-width: 768px) {
    .section-fit {
      max-width: 60rem;
    }
    #page-block-eex4ic3yqa4 {
      height: 31.4375rem;
      max-width: 100%;
    }
    #page-block-eex4ic3yqa4 .section-holder-border {
      border: 0;
    }
    #page-block-eex4ic3yqa4 .section-block {
      background: rgb(242, 244, 246);
      height: 31.4375rem;
    }
    #page-block-eex4ic3yqa4 .section-holder-overlay {
      display: none;
    }
    #element-484 {
      top: 1.5625rem;
      left: -6.75rem;
      height: 7.25rem;
      width: 7.5rem;
      z-index: 69;
    }
    #element-737 {
      top: 2.0625rem;
      left: 33.4375rem;
      height: 29.375rem;
      width: 33.5625rem;
      z-index: 80;
    }
    #element-2 {
      top: 13.5625rem;
      left: -6.75rem;
      height: 8.625rem;
      width: 36.375rem;
      z-index: 4;
      color: #37465a;
      font-size: 2.3529rem;
      line-height: 2.85rem;
      text-align: left;
    }
    #element-2 .x_abeb1804 {
      text-align: left;
      line-height: 2.875rem;
      font-size: 2.3529rem;
    }
    #element-2 .x_be5cc131 {
      color: #111111;
    }
    #element-2 .x_1dad93f4 {
      color: #ff9f29;
    }
    #element-2 strong {
      font-weight: 700;
    }
    #element-705 {
      top: 23.125rem;
      left: -7.75rem;
      height: 6.4375rem;
      width: 18.625rem;
      z-index: 79;
    }
    #page-block-9vzy99jex0e {
      height: 17.5rem;
      max-width: 100%;
    }
    #page-block-9vzy99jex0e .section-holder-border {
      border: 0;
    }
    #page-block-9vzy99jex0e .section-block {
      background: rgb(17, 17, 17);
      height: 17.5rem;
    }
    #page-block-9vzy99jex0e .section-holder-overlay {
      display: none;
    }
    #element-756 {
      top: 2.125rem;
      left: 22.0625rem;
      height: 2.375rem;
      width: 15.8125rem;
      z-index: 15;
      color: #37465a;
      font-size: 1.4861rem;
      line-height: 2.4rem;
      text-align: center;
    }
    #element-756 .x_190a7a83 {
      text-align: center;
      line-height: 2.375rem;
      font-size: 1.4861rem;
    }
    #element-756 .x_f2074b6c {
      color: #ffffff;
    }
    #element-756 strong {
      font-weight: 700;
    }
    #element-763 {
      top: 6.9375rem;
      left: -6.75rem;
      height: 8.5rem;
      width: 19.0625rem;
      z-index: 13;
    }
    #element-763 .shape {
      border: 0.0625rem dashed #ff9f29;
      border-radius: 0.375rem 0.375rem 0.375rem 0.375rem;
      background: none;
    }
    #element-768 {
      top: 6.9375rem;
      left: 20.4375rem;
      height: 8.5rem;
      width: 19.0625rem;
      z-index: 83;
    }
    #element-768 .shape {
      border: 0.0625rem dashed #ff9f29;
      border-radius: 0.375rem 0.375rem 0.375rem 0.375rem;
      background: none;
    }
    #element-767 {
      top: 6.9375rem;
      left: 47.6875rem;
      height: 8.5rem;
      width: 19.0625rem;
      z-index: 82;
    }
    #element-767 .shape {
      border: 0.0625rem dashed #ff9f29;
      border-radius: 0.375rem 0.375rem 0.375rem 0.375rem;
      background: none;
    }
    #element-769 {
      top: 6.9375rem;
      left: 47.75rem;
      height: 8.5rem;
      width: 19.0625rem;
      z-index: 84;
    }
    #element-769 .shape {
      border: 0.0625rem dashed #ff9f29;
      border-radius: 0.375rem 0.375rem 0.375rem 0.375rem;
      background: none;
    }
    #element-757 {
      top: 8.4375rem;
      left: -3.125rem;
      height: 2.75rem;
      width: 9.375rem;
      z-index: 17;
      color: #37465a;
      font-size: 2.7245rem;
      line-height: 2.75rem;
      text-align: center;
    }
    #element-757 .x_07204851 {
      text-align: center;
      line-height: 2.75rem;
      font-size: 2.7245rem;
    }
    #element-757 .x_f2074b6c {
      color: #ffffff;
    }
    #element-757 .x_92851e38 {
      text-align: left;
      background-color: transparent;
      font-style: inherit;
    }
    #element-757 strong {
      font-weight: 700;
    }
    #element-761 {
      top: 8.4375rem;
      left: 25.125rem;
      height: 2.75rem;
      width: 9.75rem;
      z-index: 56;
      color: #37465a;
      font-size: 2.7245rem;
      line-height: 2.75rem;
      text-align: center;
    }
    #element-761 .x_07204851 {
      text-align: center;
      line-height: 2.75rem;
      font-size: 2.7245rem;
    }
    #element-761 .x_f2074b6c {
      color: #ffffff;
    }
    #element-761 strong {
      font-weight: 700;
    }
    #element-759 {
      top: 8.4375rem;
      left: 52.125rem;
      height: 2.75rem;
      width: 10.3125rem;
      z-index: 18;
      color: #37465a;
      font-size: 2.7245rem;
      line-height: 2.75rem;
      text-align: center;
    }
    #element-759 .x_07204851 {
      text-align: center;
      line-height: 2.75rem;
      font-size: 2.7245rem;
    }
    #element-759 .x_f2074b6c {
      color: #ffffff;
    }
    #element-759 strong {
      font-weight: 700;
    }
    #element-760 {
      top: 11.375rem;
      left: 55.625rem;
      height: 2.625rem;
      width: 3.25rem;
      z-index: 19;
      color: #37465a;
      font-size: 1.6099rem;
      line-height: 2.6rem;
      text-align: left;
    }
    #element-760 .x_76fda3f1 {
      text-align: left;
      line-height: 2.625rem;
      font-size: 1.6099rem;
    }
    #element-760 .x_f2074b6c {
      color: #ffffff;
    }
    #element-760 strong {
      font-weight: 700;
    }
    #element-762 {
      top: 11.8125rem;
      left: -1.25rem;
      height: 2.625rem;
      width: 5.625rem;
      z-index: 57;
      color: #37465a;
      font-size: 1.6099rem;
      line-height: 2.6rem;
      text-align: center;
    }
    #element-762 .x_1925a137 {
      text-align: center;
      line-height: 2.625rem;
      font-size: 1.6099rem;
    }
    #element-762 .x_f2074b6c {
      color: #ffffff;
    }
    #element-762 strong {
      font-weight: 700;
    }
    #element-758 {
      top: 11.8125rem;
      left: 24.9375rem;
      height: 2.625rem;
      width: 10.1875rem;
      z-index: 14;
      color: #37465a;
      font-size: 1.6099rem;
      line-height: 2.6rem;
      text-align: center;
    }
    #element-758 .x_1925a137 {
      text-align: center;
      line-height: 2.625rem;
      font-size: 1.6099rem;
    }
    #element-758 .x_f2074b6c {
      color: #ffffff;
    }
    #element-758 strong {
      font-weight: 700;
    }
    #page-block-buh4ftm7fl8 {
      height: 59.1875rem;
      max-width: 100%;
    }
    #page-block-buh4ftm7fl8 .section-holder-border {
      border: 0;
    }
    #page-block-buh4ftm7fl8 .section-block {
      background: rgb(255, 255, 255);
      height: 59.1875rem;
    }
    #page-block-buh4ftm7fl8 .section-holder-overlay {
      display: none;
    }
    #element-678 {
      top: 1.75rem;
      left: -12.875rem;
      height: 18.875rem;
      width: 17.1875rem;
      z-index: 511;
    }
    #element-678 .cropped {
      background: url(../images/62508047-0-housewife-removebg-p.webp) -9.9375rem -6.625rem /
        45.0625rem 27.5rem;
    }
    #element-384 {
      top: 8.625rem;
      left: -7.875rem;
      height: 12rem;
      width: 60rem;
      z-index: 52;
    }
    #element-384 .shape {
      border: 0;
      border-radius: 0.75rem 0.75rem 0.75rem 0.75rem;
      background: rgb(255, 159, 41);
    }
    #element-148 {
      top: 11.375rem;
      left: 4.875rem;
      height: 2.375rem;
      width: 54.375rem;
      z-index: 53;
      color: #37465a;
      font-size: 2.8015rem;
      line-height: 1.3933rem;
      text-align: left;
    }
    #element-148 .x_a257d155 {
      text-align: left;
      line-height: 2.375rem;
      font-size: 2.5015rem;
    }
    #element-148 .x_f2074b6c {
      color: #ffffff;
    }
    #element-148 .x_686005f3 {
      text-align: left;
      background-color: transparent;
      font-weight: inherit;
    }
    #element-148 strong {
      font-weight: 700;
    }
    #element-155 {
      top: 16rem;
      left: 16rem;
      height: 0.5rem;
      width: 18.6875rem;
      z-index: 54;
      color: #37465a;
      font-size: 1.327rem;
      line-height: 2.1432rem;
      text-align: center;
      font-weight: 400;
    }
    #element-155 .x_e6deb37d {
      text-align: center;
      line-height: 0rem;
      font-size: 1.327rem;
    }
    #element-155 .x_92851e38 {
      text-align: left;
      background-color: transparent;
      font-style: inherit;
    }
    #element-155 .x_66d2bef0 {
      color: #fdfdfd;
    }
    #element-155 strong {
      font-weight: 700;
    }
    #element-155.paragraph {
      font-weight: 400;
    }
    #element-325 {
      top: 18.4375rem;
      left: 16rem;
      height: 1.875rem;
      width: 18.6875rem;
      z-index: 55;
      color: #37465a;
      font-size: 1.1796rem;
      line-height: 1.905rem;
      text-align: center;
      font-weight: 400;
    }
    #element-325 .x_1d9c64ff {
      text-align: center;
      line-height: 0rem;
      font-size: 1.1796rem;
    }
    #element-325 .x_f2074b6c {
      color: #ffffff;
    }
    #element-325 strong {
      font-weight: 700;
    }
    #element-325.paragraph {
      font-weight: 400;
    }
    #element-441 {
      top: 18.1875rem;
      left: 46.875rem;
      height: 22.5rem;
      width: 19.125rem;
      z-index: 62;
    }
    #element-441 .cropped {
      background: url(../images/62476064-0-family-on-bike-remov.png) -28.375rem -7.1875rem /
        51.9375rem 35.1875rem;
    }
    #element-449 {
      top: 28.5625rem;
      left: -6.1875rem;
      height: 12.1875rem;
      width: 65rem;
      z-index: 58;
    }
    #element-449 .shape {
      border: 0;
      border-radius: 0.75rem 0.75rem 0.75rem 0.75rem;
      background: rgb(255, 159, 41);
    }
    #element-450 {
      top: 30.125rem;
      left: -4.625rem;
      height: 6.375rem;
      width: 53.3125rem;
      z-index: 59;
      color: #37465a;
      font-size: 2.6267rem;
      line-height: 3.1816rem;
      text-align: left;
    }
    #element-450 .x_3b25f75b {
      text-align: left;
      line-height: 3.1875rem;
      font-size: 2.6267rem;
    }
    #element-450 .x_f2074b6c {
      color: #ffffff;
    }
    #element-450 strong {
      font-weight: 700;
    }
    #element-451 {
      top: 34.0625rem;
      left: 20.625rem;
      height: 2rem;
      width: 17.5rem;
      z-index: 60;
      color: #37465a;
      font-size: 1.2442rem;
      line-height: 2.0094rem;
      text-align: left;
      font-weight: 400;
    }
    #element-451 .x_0b167133 {
      text-align: left;
      line-height: 2rem;
      font-size: 1.2442rem;
    }
    #element-451 .x_f2074b6c {
      color: #ffffff;
    }
    #element-451 strong {
      font-weight: 700;
    }
    #element-451.paragraph {
      font-weight: 400;
    }
    #element-452 {
      top: 36.5rem;
      left: 16.625rem;
      height: 1.8125rem;
      width: 17.5rem;
      z-index: 61;
      color: #37465a;
      font-size: 1.106rem;
      line-height: 1.7861rem;
      text-align: left;
      font-weight: 400;
    }
    #element-452 .x_004937d8 {
      text-align: left;
      line-height: 1.8125rem;
      font-size: 1.106rem;
    }
    #element-452 .x_f2074b6c {
      color: #ffffff;
    }
    #element-452 strong {
      font-weight: 700;
    }
    #element-452.paragraph {
      font-weight: 400;
    }
    #page-block-ne7ha3gxh8l {
      height: 7rem;
      max-width: 100%;
    }
    #page-block-ne7ha3gxh8l .section-holder-border {
      border: 0;
    }
    #page-block-ne7ha3gxh8l .section-block {
      background: rgb(17, 17, 17);
      height: 7rem;
    }
    #page-block-ne7ha3gxh8l .section-holder-overlay {
      display: none;
    }
    #element-680 {
      top: 1.9375rem;
      left: -1.9375rem;
      height: 3.625rem;
      width: 64.3125rem;
      z-index: 77;
      color: #37465a;
      font-size: 2.2291rem;
      line-height: 3.6rem;
      text-align: left;
    }
    #element-680 .x_e22090b4 {
      text-align: left;
      line-height: 3.625rem;
      font-size: 2.2291rem;
    }
    #element-680 .x_f2074b6c {
      color: #ffffff;
    }
    #element-680 .x_1dad93f4 {
      color: #ff9f29;
    }
    #element-680 strong {
      font-weight: 700;
    }
    #page-block-laxcqvw96w8 {
      height: 95.5rem;
      max-width: 100%;
    }
    #page-block-laxcqvw96w8 .section-holder-border {
      border: 0;
    }
    #page-block-laxcqvw96w8 .section-block {
      background: rgb(255, 255, 255);
      height: 95.5rem;
    }
    #page-block-laxcqvw96w8 .section-holder-overlay {
      display: none;
    }
    #element-337 {
      top: 8.0625rem;
      left: 0;
      height: 3.3125rem;
      width: 59.9375rem;
      z-index: 11;
      color: #37465a;
      font-size: 2.7245rem;
      line-height: 3.3rem;
      text-align: center;
    }
    #element-337 .x_11909a12 {
      text-align: center;
      line-height: 3.3125rem;
      font-size: 2.7245rem;
    }
    #element-337 .x_1dad93f4 {
      color: #ff9f29;
    }
    #element-337 strong {
      font-weight: 700;
    }
    #element-651 {
      top: 18.375rem;
      left: 6.3125rem;
      height: 33.6875rem;
      width: 23.25rem;
      z-index: 76;
    }
    #element-650 {
      top: 18.375rem;
      left: 31.4375rem;
      height: 34.0625rem;
      width: 20.625rem;
      z-index: 75;
    }
    #element-777 {
      top: 30.875rem;
      left: 58.25rem;
      height: 3.3125rem;
      width: 3.3125rem;
      z-index: 89;
    }
    #element-777 .shape {
      border: 0;
      background: rgb(255, 159, 41);
    }
    #element-778 {
      top: 31.6875rem;
      left: 58.25rem;
      height: 1.625rem;
      width: 3.3125rem;
      z-index: 90;
      color: #37465a;
      font-size: 0.9907rem;
      line-height: 1.6rem;
      text-align: center;
    }
    #element-778 .x_dc68832a {
      text-align: center;
      line-height: 1.625rem;
      font-size: 0.9907rem;
    }
    #element-778 .x_be5cc131 {
      color: #111111;
    }
    #element-778 strong {
      font-weight: 700;
    }
    #element-772 {
      top: 31.3125rem;
      left: -1.625rem;
      height: 3.3125rem;
      width: 3.3125rem;
      z-index: 86;
    }
    #element-772 .shape {
      border: 0;
      background: rgb(255, 159, 41);
    }
    #element-773 {
      top: 32.125rem;
      left: -1.625rem;
      height: 1.625rem;
      width: 3.3125rem;
      z-index: 87;
      color: #37465a;
      font-size: 0.9907rem;
      line-height: 1.6rem;
      text-align: center;
    }
    #element-773 .x_dc68832a {
      text-align: center;
      line-height: 1.625rem;
      font-size: 0.9907rem;
    }
    #element-773 .x_be5cc131 {
      color: #111111;
    }
    #element-773 strong {
      font-weight: 700;
    }
    #element-775 {
      top: 35rem;
      left: 53.5625rem;
      height: 4rem;
      width: 12.6875rem;
      z-index: 88;
      color: #37465a;
      font-size: 1.2384rem;
      line-height: 2rem;
      text-align: center;
    }
    #element-775 .x_06cb0376 {
      text-align: center;
      line-height: 2rem;
      font-size: 1.2384rem;
    }
    #element-775 strong {
      font-weight: 700;
    }
    #element-774 {
      top: 35.6875rem;
      left: -7.625rem;
      height: 3.5rem;
      width: 15.3125rem;
      z-index: 85;
      color: #37465a;
      font-size: 1.2384rem;
      line-height: 1.75rem;
      text-align: center;
    }
    #element-774 .x_7df451b3 {
      text-align: center;
      line-height: 1.75rem;
      font-size: 1.2384rem;
    }
    #element-774 strong {
      font-weight: 700;
    }
    #element-643 {
      top: 57.0625rem;
      left: 34.125rem;
      height: 34.5625rem;
      width: 16.375rem;
      z-index: 73;
    }
    #element-645 {
      top: 57.375rem;
      left: 7rem;
      height: 34.5rem;
      width: 20.625rem;
      z-index: 74;
    }
    #element-468 {
      top: 70.5625rem;
      left: -1.625rem;
      height: 3.3125rem;
      width: 3.3125rem;
      z-index: 64;
    }
    #element-468 .shape {
      border: 0;
      background: rgb(255, 159, 41);
    }
    #element-469 {
      top: 71.375rem;
      left: -1.625rem;
      height: 1.625rem;
      width: 3.3125rem;
      z-index: 65;
      color: #37465a;
      font-size: 0.9907rem;
      line-height: 1.6rem;
      text-align: center;
    }
    #element-469 .x_dc68832a {
      text-align: center;
      line-height: 1.625rem;
      font-size: 0.9907rem;
    }
    #element-469 .x_be5cc131 {
      color: #111111;
    }
    #element-469 strong {
      font-weight: 700;
    }
    #element-466 {
      top: 74.75rem;
      left: -8rem;
      height: 4rem;
      width: 16rem;
      z-index: 63;
      color: #37465a;
      font-size: 1.2384rem;
      line-height: 2rem;
      text-align: center;
    }
    #element-466 .x_06cb0376 {
      text-align: center;
      line-height: 2rem;
      font-size: 1.2384rem;
    }
    #element-466 strong {
      font-weight: 700;
    }
    #element-473 {
      top: 70.5625rem;
      left: 58.0625rem;
      height: 3.3125rem;
      width: 3.3125rem;
      z-index: 67;
    }
    #element-473 .shape {
      border: 0;
      background: rgb(255, 159, 41);
    }
    #element-474 {
      top: 71.375rem;
      left: 58.0625rem;
      height: 1.625rem;
      width: 3.3125rem;
      z-index: 68;
      color: #37465a;
      font-size: 0.9907rem;
      line-height: 1.6rem;
      text-align: center;
    }
    #element-474 .x_dc68832a {
      text-align: center;
      line-height: 1.625rem;
      font-size: 0.9907rem;
    }
    #element-474 .x_be5cc131 {
      color: #111111;
    }
    #element-474 strong {
      font-weight: 700;
    }
    #element-471 {
      top: 74.6875rem;
      left: 52.75rem;
      height: 4rem;
      width: 14.3125rem;
      z-index: 66;
      color: #37465a;
      font-size: 1.2384rem;
      line-height: 2rem;
      text-align: center;
    }
    #element-471 .x_06cb0376 {
      text-align: center;
      line-height: 2rem;
      font-size: 1.2384rem;
    }
    #element-471 strong {
      font-weight: 700;
    }
    #page-block-05m97vylt1m {
      height: 5.75rem;
      max-width: 100%;
    }
    #page-block-05m97vylt1m .section-holder-border {
      border: 0;
    }
    #page-block-05m97vylt1m .section-block {
      background: rgb(255, 255, 255);
      height: 5.75rem;
    }
    #page-block-05m97vylt1m .section-holder-overlay {
      display: none;
    }
    #element-779 {
      top: 1.5625rem;
      left: 0;
      height: 2.875rem;
      width: 59.9375rem;
      z-index: 50;
      color: #37465a;
      font-size: 2.3529rem;
      line-height: 2.85rem;
      text-align: center;
    }
    #element-779 .x_b614627d {
      text-align: center;
      line-height: 2.875rem;
      font-size: 2.3529rem;
    }
    #element-779 .x_1dad93f4 {
      color: #ff9f29;
    }
    #element-779 strong {
      font-weight: 700;
    }
    #page-block-ppa8syz3e5 {
      height: 16.8125rem;
      max-width: 100%;
    }
    #page-block-ppa8syz3e5 .section-holder-border {
      border: 0;
    }
    #page-block-ppa8syz3e5 .section-block {
      background: rgb(17, 17, 17);
      height: 16.8125rem;
    }
    #page-block-ppa8syz3e5 .section-holder-overlay {
      display: none;
    }
    #element-755 {
      top: 0;
      left: -5rem;
      height: 16.8125rem;
      width: 70rem;
      z-index: 81;
    }
    #page-block-euq3gv5pn19 {
      height: 33.6875rem;
      max-width: 100%;
    }
    #page-block-euq3gv5pn19 .section-holder-border {
      border: 0;
    }
    #page-block-euq3gv5pn19 .section-block {
      background: rgb(242, 244, 246);
      height: 33.6875rem;
    }
    #page-block-euq3gv5pn19 .section-holder-overlay {
      display: none;
    }
    #element-228 {
      top: 4.125rem;
      left: 0;
      height: 3.3125rem;
      width: 59.9375rem;
      z-index: 21;
      color: #37465a;
      font-size: 2.7245rem;
      line-height: 3.3rem;
      text-align: center;
    }
    #element-228 .x_11909a12 {
      text-align: center;
      line-height: 3.3125rem;
      font-size: 2.7245rem;
    }
    #element-228 .x_be5cc131 {
      color: #111111;
    }
    #element-228 .x_1dad93f4 {
      color: #ff9f29;
    }
    #element-228 strong {
      font-weight: 700;
    }
    #element-226 {
      top: 9.8125rem;
      left: -2.8125rem;
      height: 21.125rem;
      width: 19.1875rem;
      z-index: 22;
    }
    #element-226 .shape {
      border: 0;
      border-radius: 0.75rem 0.75rem 0.75rem 0.75rem;
      background: rgb(255, 255, 255);
    }
    #element-238 {
      top: 11.6875rem;
      left: 3rem;
      height: 7.5625rem;
      width: 7.5625rem;
      z-index: 25;
    }
    #element-238 .shape {
      border: 0.0625rem solid #fdfdfd;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mM89h8AApEBx2iaqpQAAAAASUVORK5CYII=)
        repeat rgb(240, 243, 245) 50% 50% / cover;
    }
    #element-233 {
      top: 20.75rem;
      left: -1.5625rem;
      height: 3.625rem;
      width: 16.6875rem;
      z-index: 23;
      color: #37465a;
      font-size: 1.1146rem;
      line-height: 1.8rem;
      text-align: center;
    }
    #element-233 .x_e6507cf2 {
      text-align: center;
      line-height: 1.8125rem;
      font-size: 1.1146rem;
    }
    #element-233 .x_be5cc131 {
      color: #111111;
    }
    #element-233 .x_686005f3 {
      text-align: left;
      background-color: transparent;
      font-weight: inherit;
    }
    #element-233 strong {
      font-weight: 700;
    }
    #element-234 {
      top: 26.5rem;
      left: -1.5625rem;
      height: 3.25rem;
      width: 16.6875rem;
      z-index: 24;
      color: #37465a;
      font-size: 0.9907rem;
      line-height: 1.6rem;
      text-align: center;
    }
    #element-234 .x_dc68832a {
      text-align: center;
      line-height: 1.625rem;
      font-size: 0.9907rem;
    }
    #element-234 .x_1dad93f4 {
      color: #ff9f29;
    }
    #element-234 strong {
      font-weight: 700;
    }
    #element-245 {
      top: 9.8125rem;
      left: 20.375rem;
      height: 21.125rem;
      width: 19.1875rem;
      z-index: 28;
    }
    #element-245 .shape {
      border: 0;
      border-radius: 0.75rem 0.75rem 0.75rem 0.75rem;
      background: rgb(255, 255, 255);
    }
    #element-248 {
      top: 11.6875rem;
      left: 26.1875rem;
      height: 7.5625rem;
      width: 7.5625rem;
      z-index: 31;
    }
    #element-248 .shape {
      border: 0.0625rem solid #fdfdfd;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mM89h8AApEBx2iaqpQAAAAASUVORK5CYII=)
        repeat rgb(240, 243, 245) 50% 50% / cover;
    }
    #element-246 {
      top: 20.75rem;
      left: 21rem;
      height: 5.4375rem;
      width: 17.9375rem;
      z-index: 29;
      color: #37465a;
      font-size: 1.1146rem;
      line-height: 1.8rem;
      text-align: center;
    }
    #element-246 .x_e6507cf2 {
      text-align: center;
      line-height: 1.8125rem;
      font-size: 1.1146rem;
    }
    #element-246 .x_be5cc131 {
      color: #111111;
    }
    #element-246 .x_686005f3 {
      text-align: left;
      background-color: transparent;
      font-weight: inherit;
    }
    #element-246 strong {
      font-weight: 700;
    }
    #element-247 {
      top: 26.5rem;
      left: 21.625rem;
      height: 3.25rem;
      width: 16.6875rem;
      z-index: 30;
      color: #37465a;
      font-size: 0.9907rem;
      line-height: 1.6rem;
      text-align: center;
    }
    #element-247 .x_dc68832a {
      text-align: center;
      line-height: 1.625rem;
      font-size: 0.9907rem;
    }
    #element-247 .x_1dad93f4 {
      color: #ff9f29;
    }
    #element-247 strong {
      font-weight: 700;
    }
    #element-252 {
      top: 9.8125rem;
      left: 43.4375rem;
      height: 21.125rem;
      width: 19.1875rem;
      z-index: 32;
    }
    #element-252 .shape {
      border: 0;
      border-radius: 0.75rem 0.75rem 0.75rem 0.75rem;
      background: rgb(255, 255, 255);
    }
    #element-258 {
      top: 11.6875rem;
      left: 49.25rem;
      height: 7.5625rem;
      width: 7.5625rem;
      z-index: 35;
    }
    #element-258 .shape {
      border: 0.0625rem solid #fdfdfd;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mM89h8AApEBx2iaqpQAAAAASUVORK5CYII=)
        repeat rgb(240, 243, 245) 56% 35% / cover;
    }
    #element-254 {
      top: 20.75rem;
      left: 44.6875rem;
      height: 3.625rem;
      width: 16.6875rem;
      z-index: 33;
      color: #37465a;
      font-size: 1.1146rem;
      line-height: 1.8rem;
      text-align: center;
    }
    #element-254 .x_e6507cf2 {
      text-align: center;
      line-height: 1.8125rem;
      font-size: 1.1146rem;
    }
    #element-254 .x_7b2817bf {
      color: #000000;
    }
    #element-254 .x_686005f3 {
      text-align: left;
      background-color: transparent;
      font-weight: inherit;
    }
    #element-254 strong {
      font-weight: 700;
    }
    #element-256 {
      top: 26.5rem;
      left: 44.6875rem;
      height: 3.25rem;
      width: 16.6875rem;
      z-index: 34;
      color: #37465a;
      font-size: 0.9907rem;
      line-height: 1.6rem;
      text-align: center;
    }
    #element-256 .x_dc68832a {
      text-align: center;
      line-height: 1.625rem;
      font-size: 0.9907rem;
    }
    #element-256 .x_1dad93f4 {
      color: #ff9f29;
    }
    #element-256 strong {
      font-weight: 700;
    }
    #page-block-edqepzfrovn {
      height: 42.9375rem;
      max-width: 100%;
    }
    #page-block-edqepzfrovn .section-holder-border {
      border: 0;
    }
    #page-block-edqepzfrovn .section-block {
      background: rgb(255, 255, 255);
      height: 42.9375rem;
    }
    #page-block-edqepzfrovn .section-holder-overlay {
      display: none;
    }
    #element-210 {
      top: 3.5625rem;
      left: 0;
      height: 3.3125rem;
      width: 59.9375rem;
      z-index: 7;
      color: #37465a;
      font-size: 2.7245rem;
      line-height: 3.3rem;
      text-align: center;
    }
    #element-210 .x_11909a12 {
      text-align: center;
      line-height: 3.3125rem;
      font-size: 2.7245rem;
    }
    #element-210 .x_be5cc131 {
      color: #111111;
    }
    #element-210 .x_1dad93f4 {
      color: #ff9f29;
    }
    #element-210 strong {
      font-weight: 700;
    }
    #element-214 {
      top: 9rem;
      left: 0;
      height: 32.0625rem;
      width: 59.9375rem;
      z-index: 20;
    }
    element-214 iframe {
      width: 959px;
      height: 513px;
    }
    #element-212 {
      top: 24.1875rem;
      left: 27.375rem;
      height: 5.125rem;
      width: 5.125rem;
      z-index: 8;
    }
    #page-block-rprp5g5gpym {
      height: 11.5625rem;
      max-width: 100%;
    }
    #page-block-rprp5g5gpym .section-holder-border {
      border: 0;
    }
    #page-block-rprp5g5gpym .section-block {
      background: rgb(251, 251, 251);
      height: 11.5625rem;
    }
    #page-block-rprp5g5gpym .section-holder-overlay {
      display: none;
    }
    #element-109 {
      top: 2.5625rem;
      left: 0;
      height: 3.3125rem;
      width: 59.9375rem;
      z-index: 6;
      color: #37465a;
      font-size: 2.7245rem;
      line-height: 3.3rem;
      text-align: center;
    }
    #element-109 .x_11909a12 {
      text-align: center;
      line-height: 3.3125rem;
      font-size: 2.7245rem;
    }
    #element-109 .x_be5cc131 {
      color: #111111;
    }
    #element-109 .x_1dad93f4 {
      color: #ff9f29;
    }
    #element-109 strong {
      font-weight: 700;
    }
    #element-261 {
      top: 7rem;
      left: 2.4375rem;
      height: 2.8125rem;
      width: 55.125rem;
      z-index: 36;
      color: #37465a;
      font-size: 1.7337rem;
      line-height: 2.8rem;
      text-align: center;
    }
    #element-261 .x_2ec5d3d8 {
      text-align: center;
      line-height: 2.8125rem;
      font-size: 1.7337rem;
    }
    #element-261 .x_be5cc131 {
      color: #111111;
    }
    #element-261 .x_1dad93f4 {
      color: #ff9f29;
    }
    #element-261 strong {
      font-weight: 700;
    }
    #page-block-xtxeeaj9qhq {
      height: 45.5625rem;
      max-width: 100%;
    }
    #page-block-xtxeeaj9qhq .section-holder-border {
      border: 0;
    }
    #page-block-xtxeeaj9qhq .section-block {
      background: rgb(17, 17, 17);
      height: 45.5625rem;
    }
    #page-block-xtxeeaj9qhq .section-holder-overlay {
      display: none;
    }
    #element-178 {
      top: 2.625rem;
      left: -4.9375rem;
      height: 19rem;
      width: 39.625rem;
      z-index: 10;
    }
    #element-178 .shape {
      border: 0;
      border-radius: 0.75rem 0.75rem 0.75rem 0.75rem;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mM89h8AApEBx2iaqpQAAAAASUVORK5CYII=)
        repeat rgb(255, 255, 255) 50% 50% / cover;
    }
    #element-177 {
      top: 2.625rem;
      left: 36.5625rem;
      height: 19rem;
      width: 28.1875rem;
      z-index: 9;
    }
    #element-177 .shape {
      border: 0;
      border-radius: 0.375rem 0.375rem 0.375rem 0.375rem;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mM89h8AApEBx2iaqpQAAAAASUVORK5CYII=)
        repeat rgb(255, 255, 255) 50% 50% / cover;
    }
    #element-179 {
      top: 22.75rem;
      left: 24.75rem;
      height: 19.125rem;
      width: 40.1875rem;
      z-index: 12;
    }
    #element-179 .shape {
      border: 0;
      border-radius: 0.375rem 0.375rem 0.375rem 0.375rem;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mM89h8AApEBx2iaqpQAAAAASUVORK5CYII=)
        repeat rgb(255, 255, 255) 50% 50% / cover;
    }
    #element-1779 {
        top: 22.75rem;
        right: 36.75rem;
        height: 19.125rem;
        width: 28.1875rem;
        z-index: 12;
      }
      #element-1779 .shape {
        border: 0;
        border-radius: 0.375rem 0.375rem 0.375rem 0.375rem;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mM89h8AApEBx2iaqpQAAAAASUVORK5CYII=)
          repeat rgb(255, 255, 255) 50% 50% / cover;
      }
    #page-block-2rymn9d97la {
      height: 63.5rem;
      max-width: 100%;
    }
    #page-block-2rymn9d97la .section-holder-border {
      border: 0;
    }
    #page-block-2rymn9d97la .section-block {
      background: rgb(255, 255, 255);
      height: 63.5rem;
    }
    #page-block-2rymn9d97la .section-holder-overlay {
      display: none;
    }
    #element-582 {
      top: 5.25rem;
      left: -0.0625rem;
      height: 3.3125rem;
      width: 60rem;
      z-index: 27;
      color: #37465a;
      font-size: 2.7245rem;
      line-height: 3.3rem;
      text-align: center;
    }
    #element-582 .x_11909a12 {
      text-align: center;
      line-height: 3.3125rem;
      font-size: 2.7245rem;
    }
    #element-582 .x_1dad93f4 {
      color: #ff9f29;
    }
    #element-582 .x_be5cc131 {
      color: #111111;
    }
    #element-582 strong {
      font-weight: 700;
    }
    #element-597 {
      top: 8.5625rem;
      left: 6rem;
      height: 30.9375rem;
      width: 48rem;
      z-index: 71;
    }
    #element-598 {
      top: 37.625rem;
      left: 32.375rem;
      height: 25.5rem;
      width: 34.5rem;
      z-index: 72;
    }
    #element-596 {
      top: 37.6875rem;
      left: -7.375rem;
      height: 25.25rem;
      width: 33.9375rem;
      z-index: 70;
    }
    #page-block-ej3l1zy2k3t {
      height: 44.6875rem;
      max-width: 100%;
    }
    #page-block-ej3l1zy2k3t .section-holder-border {
      border: 0;
    }
    #page-block-ej3l1zy2k3t .section-block {
      background: rgb(255, 255, 255);
      height: 44.6875rem;
    }
    #page-block-ej3l1zy2k3t .section-holder-overlay {
      display: none;
    }
    #element-262 {
      top: 5.0625rem;
      left: 0;
      height: 3.3125rem;
      width: 60rem;
      z-index: 26;
      color: #37465a;
      font-size: 2.7245rem;
      line-height: 3.3rem;
      text-align: center;
    }
    #element-262 .x_11909a12 {
      text-align: center;
      line-height: 3.3125rem;
      font-size: 2.7245rem;
    }
    #element-262 .x_1dad93f4 {
      color: #ff9f29;
    }
    #element-262 .x_be5cc131 {
      color: #111111;
    }
    #element-262 strong {
      font-weight: 700;
    }
    #element-263 {
      top: 12.4375rem;
      left: -4.3125rem;
      height: 27.625rem;
      width: 18.75rem;
      z-index: 37;
    }
    #element-263 .shape {
      border: 0;
      border-radius: 0 0 0.375rem 0.375rem;
      background: rgb(240, 243, 245);
    }
    #element-266 {
      top: 12.4375rem;
      left: -4.3125rem;
      height: 16.25rem;
      width: 18.75rem;
      z-index: 39;
    }
    #element-266 .shape {
      border: 0;
      border-radius: 0.375rem 0.375rem 0 0;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mM89h8AApEBx2iaqpQAAAAASUVORK5CYII=)
        repeat rgb(255, 255, 255) 50% 50% / cover;
    }
    #element-265 {
      top: 30.1875rem;
      left: -2.6875rem;
      height: 4.6875rem;
      width: 15.5rem;
      z-index: 38;
      color: #37465a;
      font-size: 1.1146rem;
      line-height: 1.575rem;
      text-align: center;
    }
    #element-265 .x_bcbce646 {
      text-align: center;
      line-height: 1.5625rem;
      font-size: 1.1146rem;
    }
    #element-265 .x_be5cc131 {
      color: #111111;
    }
    #element-265 strong {
      font-weight: 700;
    }
    #element-279 {
      top: 35.5rem;
      left: 1.0625rem;
      height: 3.125rem;
      width: 8rem;
      z-index: 38;
    }
    #element-287 {
      top: 12.4375rem;
      left: 20.5625rem;
      height: 16.25rem;
      width: 18.75rem;
      z-index: 42;
    }
    #element-287 .shape {
      border: 0;
      border-radius: 0.375rem 0.375rem 0 0;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mM89h8AApEBx2iaqpQAAAAASUVORK5CYII=)
        repeat rgb(255, 255, 255) 50% 50% / cover;
    }
    #element-285 {
      top: 30.1875rem;
      left: 21.875rem;
      height: 4.6875rem;
      width: 16.1875rem;
      z-index: 41;
      color: #37465a;
      font-size: 1.1146rem;
      line-height: 1.575rem;
      text-align: center;
    }
    #element-285 .x_bcbce646 {
      text-align: center;
      line-height: 1.5625rem;
      font-size: 1.1146rem;
    }
    #element-285 .x_be5cc131 {
      color: #111111;
    }
    #element-285 strong {
      font-weight: 700;
    }
    #element-283 {
      top: 12.4375rem;
      left: 20.5625rem;
      height: 27.625rem;
      width: 18.75rem;
      z-index: 40;
    }
    #element-283 .shape {
      border: 0;
      border-radius: 0 0 0.375rem 0.375rem;
      background: rgb(240, 243, 245);
    }
    #element-289 {
      top: 35.875rem;
      left: 25.9375rem;
      height: 3.125rem;
      width: 8rem;
      z-index: 44;
    }
    #element-289 .cropped {
      background: url(../images/62468546-0-media2logo.png) -1.375rem
        0 / 10.75rem 3.125rem;
    }
    #element-319 {
      top: 12.4375rem;
      left: 45.375rem;
      height: 16.25rem;
      width: 18.75rem;
      z-index: 47;
    }
    #element-319 .shape {
      border: 0;
      border-radius: 0.375rem 0.375rem 0 0;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mM89h8AApEBx2iaqpQAAAAASUVORK5CYII=)
        repeat rgb(255, 255, 255) 50% 50% / cover;
    }
    #element-317 {
      top: 30.1875rem;
      left: 46.6875rem;
      height: 4.6875rem;
      width: 16.1875rem;
      z-index: 46;
      color: #37465a;
      font-size: 1.1146rem;
      line-height: 1.575rem;
      text-align: center;
    }
    #element-317 .x_bcbce646 {
      text-align: center;
      line-height: 1.5625rem;
      font-size: 1.1146rem;
    }
    #element-317 .x_be5cc131 {
      color: #111111;
    }
    #element-317 strong {
      font-weight: 700;
    }
    #element-314 {
      top: 12.4375rem;
      left: 45.375rem;
      height: 27.625rem;
      width: 18.75rem;
      z-index: 45;
    }
    #element-314 .shape {
      border: 0;
      border-radius: 0 0 0.375rem 0.375rem;
      background: rgb(240, 243, 245);
    }
    #element-321 {
      top: 35.5625rem;
      left: 50.75rem;
      height: 3.125rem;
      width: 8rem;
      z-index: 49;
    }
    #element-321 .cropped {
      background: url(../images/62468548-0-media1logo.png) -1.375rem
        0 / 10.75rem 3.125rem;
    }
    #page-block-jkogznfscv {
      height: 7.8125rem;
      max-width: 100%;
    }
    #page-block-jkogznfscv .section-holder-border {
      border: 0;
    }
    #page-block-jkogznfscv .section-block {
      background: rgb(30, 30, 30);
      height: 7.8125rem;
    }
    #page-block-jkogznfscv .section-holder-overlay {
      display: none;
    }
    #element-781 {
      top: 0.8125rem;
      left: -7.625rem;
      height: 0.8125rem;
      width: 23.125rem;
      z-index: 91;
      color: #37465a;
      font-size: 1.1146rem;
      line-height: 1.8rem;
      text-align: center;
    }
    #element-781 .x_e6507cf2 {
      text-align: center;
      line-height: 0.8125rem;
      font-size: 1.1146rem;
    }
    #element-781 .x_2b6404b1 {
      color: #f2f4f6;
    }
    #element-782 {
      top: 1.8125rem;
      left: 18.375rem;
      height: 1.8125rem;
      width: 23.125rem;
      z-index: 92;
      color: #37465a;
      font-size: 1.1146rem;
      line-height: 1.8rem;
      text-align: center;
    }
    #element-782 .x_e6507cf2 {
      text-align: center;
      line-height: 1.8125rem;
      font-size: 1.1146rem;
    }
    #element-782 .x_2b6404b1 {
      color: #f2f4f6;
    }
    #element-783 {
      top: 1.8125rem;
      left: 44.5rem;
      height: 1.8125rem;
      width: 23.125rem;
      z-index: 93;
      color: #37465a;
      font-size: 1.1146rem;
      line-height: 1.8rem;
      text-align: center;
    }
    #element-783 .x_e6507cf2 {
      text-align: center;
      line-height: 1.8125rem;
      font-size: 1.1146rem;
    }
    #element-783 .x_2b6404b1 {
      color: #f2f4f6;
    }
    #element-784 {
      top: 1.625rem;
      left: 23.375rem;
      height: 2rem;
      width: 2rem;
      z-index: 94;
    }
    #element-785 {
      top: 1.875rem;
      left: 26.125rem;
      height: 1.625rem;
      width: 10.375rem;
      z-index: 95;
      color: #37465a;
      font-size: 0.9907rem;
      line-height: 1.6rem;
      text-align: left;
    }
    #element-785 .x_3579aa00 {
      text-align: left;
      line-height: 1.625rem;
      font-size: 0.9907rem;
    }
    #element-785 .x_2b6404b1 {
      color: #f2f4f6;
    }
    #element-75 {
      top: 4.5625rem;
      left: 10.9375rem;
      height: 5.375rem;
      width: 39.5625rem;
      z-index: 5;
      color: #37465a;
      font-size: 0.8669rem;
      line-height: 1.4rem;
      text-align: center;
    }
    #element-75 .x_938e8cfc {
      text-align: center;
      line-height: 1.375rem;
      font-size: 0.8669rem;
    }
    #element-75 .x_76ffb0ab {
      color: #e8e8e8;
    }
    #page-block-wlj9406vt9 {
      height: 5.25rem;
      max-width: 100%;
    }
    #page-block-wlj9406vt9 .section-holder-border {
      border: 0;
    }
    #page-block-wlj9406vt9 .section-block {
      background: rgb(17, 17, 17);
      height: 5.25rem;
    }
    #page-block-wlj9406vt9 .section-holder-overlay {
      display: none;
    }
    #element-681 {
      top: 0.1875rem;
      left: 49.0625rem;
      height: 4.875rem;
      width: 14.125rem;
      z-index: 78;
    }
    #element-432 {
      top: 1.9375rem;
      left: -5rem;
      height: 1.9375rem;
      width: 32.75rem;
      z-index: 16;
      color: #37465a;
      font-size: 1.6099rem;
      line-height: 1.95rem;
      text-align: left;
    }
    #element-432 .x_56c6f2d0 {
      text-align: left;
      line-height: 1.9375rem;
      font-size: 1.6099rem;
    }
    #element-432 .x_f2074b6c {
      color: #ffffff;
    }
    #element-432 strong {
      font-weight: 700;
    }
  }
  .sticky-footer {
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 1000000;
  }
  .col-md,
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-auto,
  .col-sm,
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-auto,
  .col-xl-auto {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }
  .col {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-1 {
    margin-left: 8.333333%;
  }
  .offset-2 {
    margin-left: 16.666667%;
  }
  .offset-3 {
    margin-left: 25%;
  }
  .offset-4 {
    margin-left: 33.333333%;
  }
  .offset-5 {
    margin-left: 41.666667%;
  }
  .offset-6 {
    margin-left: 50%;
  }
  .offset-7 {
    margin-left: 58.333333%;
  }
  .offset-8 {
    margin-left: 66.666667%;
  }
  .offset-9 {
    margin-left: 75%;
  }
  .offset-10 {
    margin-left: 83.333333%;
  }
  .offset-11 {
    margin-left: 91.666667%;
  }
  @media (min-width: 576px) {
    .col-sm {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%;
    }
    .col-sm-auto {
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    }
    .col-sm-1 {
      -ms-flex: 0 0 8.333333%;
      flex: 0 0 8.333333%;
      max-width: 8.333333%;
    }
    .col-sm-2 {
      -ms-flex: 0 0 16.666667%;
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }
    .col-sm-3 {
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-sm-4 {
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
    .col-sm-5 {
      -ms-flex: 0 0 41.666667%;
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }
    .col-sm-6 {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-sm-7 {
      -ms-flex: 0 0 58.333333%;
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }
    .col-sm-8 {
      -ms-flex: 0 0 66.666667%;
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }
    .col-sm-9 {
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-sm-10 {
      -ms-flex: 0 0 83.333333%;
      flex: 0 0 83.333333%;
      max-width: 83.333333%;
    }
    .col-sm-11 {
      -ms-flex: 0 0 91.666667%;
      flex: 0 0 91.666667%;
      max-width: 91.666667%;
    }
    .col-sm-12 {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .order-sm-first {
      -ms-flex-order: -1;
      order: -1;
    }
    .order-sm-last {
      -ms-flex-order: 13;
      order: 13;
    }
    .order-sm-0 {
      -ms-flex-order: 0;
      order: 0;
    }
    .order-sm-1 {
      -ms-flex-order: 1;
      order: 1;
    }
    .order-sm-2 {
      -ms-flex-order: 2;
      order: 2;
    }
    .order-sm-3 {
      -ms-flex-order: 3;
      order: 3;
    }
    .order-sm-4 {
      -ms-flex-order: 4;
      order: 4;
    }
    .order-sm-5 {
      -ms-flex-order: 5;
      order: 5;
    }
    .order-sm-6 {
      -ms-flex-order: 6;
      order: 6;
    }
    .order-sm-7 {
      -ms-flex-order: 7;
      order: 7;
    }
    .order-sm-8 {
      -ms-flex-order: 8;
      order: 8;
    }
    .order-sm-9 {
      -ms-flex-order: 9;
      order: 9;
    }
    .order-sm-10 {
      -ms-flex-order: 10;
      order: 10;
    }
    .order-sm-11 {
      -ms-flex-order: 11;
      order: 11;
    }
    .order-sm-12 {
      -ms-flex-order: 12;
      order: 12;
    }
    .offset-sm-0 {
      margin-left: 0;
    }
    .offset-sm-1 {
      margin-left: 8.333333%;
    }
    .offset-sm-2 {
      margin-left: 16.666667%;
    }
    .offset-sm-3 {
      margin-left: 25%;
    }
    .offset-sm-4 {
      margin-left: 33.333333%;
    }
    .offset-sm-5 {
      margin-left: 41.666667%;
    }
    .offset-sm-6 {
      margin-left: 50%;
    }
    .offset-sm-7 {
      margin-left: 58.333333%;
    }
    .offset-sm-8 {
      margin-left: 66.666667%;
    }
    .offset-sm-9 {
      margin-left: 75%;
    }
    .offset-sm-10 {
      margin-left: 83.333333%;
    }
    .offset-sm-11 {
      margin-left: 91.666667%;
    }
  }
  @media (min-width: 768px) {
    .col-md {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%;
    }
    .col-md-auto {
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    }
    .col-md-1 {
      -ms-flex: 0 0 8.333333%;
      flex: 0 0 8.333333%;
      max-width: 8.333333%;
    }
    .col-md-2 {
      -ms-flex: 0 0 16.666667%;
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }
    .col-md-3 {
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-md-4 {
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
    .col-md-5 {
      -ms-flex: 0 0 41.666667%;
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }
    .col-md-6 {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-md-7 {
      -ms-flex: 0 0 58.333333%;
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }
    .col-md-8 {
      -ms-flex: 0 0 66.666667%;
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }
    .col-md-9 {
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-md-10 {
      -ms-flex: 0 0 83.333333%;
      flex: 0 0 83.333333%;
      max-width: 83.333333%;
    }
    .col-md-11 {
      -ms-flex: 0 0 91.666667%;
      flex: 0 0 91.666667%;
      max-width: 91.666667%;
    }
    .col-md-12 {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .order-md-first {
      -ms-flex-order: -1;
      order: -1;
    }
    .order-md-last {
      -ms-flex-order: 13;
      order: 13;
    }
    .order-md-0 {
      -ms-flex-order: 0;
      order: 0;
    }
    .order-md-1 {
      -ms-flex-order: 1;
      order: 1;
    }
    .order-md-2 {
      -ms-flex-order: 2;
      order: 2;
    }
    .order-md-3 {
      -ms-flex-order: 3;
      order: 3;
    }
    .order-md-4 {
      -ms-flex-order: 4;
      order: 4;
    }
    .order-md-5 {
      -ms-flex-order: 5;
      order: 5;
    }
    .order-md-6 {
      -ms-flex-order: 6;
      order: 6;
    }
    .order-md-7 {
      -ms-flex-order: 7;
      order: 7;
    }
    .order-md-8 {
      -ms-flex-order: 8;
      order: 8;
    }
    .order-md-9 {
      -ms-flex-order: 9;
      order: 9;
    }
    .order-md-10 {
      -ms-flex-order: 10;
      order: 10;
    }
    .order-md-11 {
      -ms-flex-order: 11;
      order: 11;
    }
    .order-md-12 {
      -ms-flex-order: 12;
      order: 12;
    }
    .offset-md-0 {
      margin-left: 0;
    }
    .offset-md-1 {
      margin-left: 8.333333%;
    }
    .offset-md-2 {
      margin-left: 16.666667%;
    }
    .offset-md-3 {
      margin-left: 25%;
    }
    .offset-md-4 {
      margin-left: 33.333333%;
    }
    .offset-md-5 {
      margin-left: 41.666667%;
    }
    .offset-md-6 {
      margin-left: 50%;
    }
    .offset-md-7 {
      margin-left: 58.333333%;
    }
    .offset-md-8 {
      margin-left: 66.666667%;
    }
    .offset-md-9 {
      margin-left: 75%;
    }
    .offset-md-10 {
      margin-left: 83.333333%;
    }
    .offset-md-11 {
      margin-left: 91.666667%;
    }
  }
  @media (min-width: 768px) {
    .mobile-only {
      display: none !important;
    }
  }
  @media (max-width: 767px) {
    .desktop-only {
      display: none !important;
    }
    #element-178 {
        top: 3rem;
        left: 7%;
        height: 18.5625rem;
        width: 21.5rem;
        z-index: 70;
      }
    #element-177 {
        top: 23rem;
        left: 7%;
        height: 18.5625rem;
        width: 21.5rem;
        z-index: 71;
      }
    #element-179 {
        top: 43rem;
        left: 7%;
        height: 18.5625rem;
        width: 21.5rem;
        z-index: 72;
      }
    #element-1779 {
          top: 63rem;
          left: 7%;
          height: 18.5625rem;
          width: 21.5rem;
          z-index: 75;
        }
  }